import { AdaptiveModalProps } from "./adaptive-modal.interface";
import { StyledModal } from "./adaptive-modal.styled";

export const AdaptiveModal = ({
    showMobileHeaderShadow = true,
    testId,
    ...props
}: AdaptiveModalProps) => {
    return (
        <StyledModal
            centered
            withCloseButton={false}
            overlayOpacity={0.15}
            transition="slide-left"
            $showMobileHeaderShadow={showMobileHeaderShadow}
            data-testid={testId}
            {...props}
        />
    );
};
